.modal-upload-form {
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;

  .item {
    display: flex;
    width: 100%;
    border: 1px solid #eaeaea;

    .item-title {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 30%;
      font-weight: bold;
      text-align: center;
      background-color: rgba(99, 81, 190, 0.1);
      padding: 12px 8px;
    }
    .item-field {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 70%;
      padding: 0 0 0 20px;
      padding: 10px;
    }
  }
}

// bootstrap
.btn-primary {
  // color: #fff;
  background-color: #6351be;
  border-color: #6351be;
  &:disabled {
    background-color: lightgray;
    border-color: lightgray;
  }
}

// .react-datepicker__input-container {
//   input {
//     background-color: #e9ecef;
//     opacity: 1;
//     display: block;
//     width: 100%;
//     height: calc(1.5em + 0.75rem + 2px);
//     padding: 0.375rem 0.75rem;
//     font-size: 1rem;
//     font-weight: 400;
//     line-height: 1.5;
//     color: #495057;
//     background-color: #fff;
//     background-clip: padding-box;
//     border: 1px solid #ced4da;
//     border-radius: 0.25rem;
//     transition: border-color 0.15s
//   }
// }

.modal {
  .modal-dialog {
    &.modal-50w {
      max-width: 50%;
      min-width: 600px;
    }
    &.modal-60w {
      max-width: 60%;
      min-width: 700px;
    }
    &.modal-90w {
      max-width: 90%;
    }
  }
}

.form-control-file {
  outline-color: #dc3545;
  &.is-invalid {
    border: 1px solid #dc3545;
    border-radius: 3.2px;
  }
}

.react-datepicker-popper {
  z-index: 11 !important; // editor와 겹치는 문제 때문에
}
.Toastify {
  .Toastify__toast-container--bottom-center {
    bottom: calc(174px - 40px) !important;
  }
  .Toastify__toast-container {
    width: initial;
    .Toastify__toast--default {
      // box-shadow: none;
      background-color: #343957;
      // border-radius: 20px;
      min-height: 1px;
      padding: 9px 40px;
      .Toastify__toast-body {
        text-align: center;
        // box-shadow: none;
        background-color: #343957;
        color: white;
        font-size: 14px;
        word-break: break-all;
      }
    }
  }
}

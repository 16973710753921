.layout-header {
  position: fixed;
  width: 100%;
  height: 60px;
  z-index: 12;
  color: black;
  background-color: white;
  box-shadow: 0 5px 20px rgb(0 0 0 / 10%);

  display: flex;
  justify-content: flex-end;
  align-items: center;
  .icon {
    margin-right: 6px;
  }
  .name {
    margin-right: 20px;
  }
  .logout {
    margin-right: 20px;
  }
}

.center-layout-container {
  flex: 1;
  display: flex;
  font-size: 40px;
  font-weight: bold;
  padding-left: 280px;
  justify-content: flex-start;
}

.layout-sidebar {
  position: fixed;
  overflow-y: auto;
  // margin-top: 60px;
  width: 260px;
  height: 100vh;
  background-color: #343957;
  z-index: 13;
  .menus {
    background-color: #343957;
    .logo {
      width: 260px;
      height: 168px;
      padding-top: 20px;
      padding-bottom: 40px;
      text-align: center;
      background-color: rgba(52, 57, 87, 1);
      color: white;
    }
    .title1 {
      padding: 8px 8px 8px 12px;
      font-weight: bold;
      color: white;
      font-size: 14px;
    }
    .title2 {
      display: block;
      cursor: pointer;
      padding: 6px 6px 6px 24px;
      color: #bdbdc7;
      &:hover {
        color: white;
        background-color: rgba(107, 81, 223, 0.5);
      }
      &.active {
        font-weight: bold;
        color: white;
        background-color: #6b51df;
      }
    }
  }
}

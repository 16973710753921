.vocabulary-tabs {
  display: flex;
  margin-top: 40px;
  width: 100%;
  border-bottom: 1px solid black;
  .tab {
    width: 50%;
    padding: 10px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    &:hover {
      background-color: rgba(99, 81, 190, 0.1);
    }
    &.focus {
      border-bottom: 2px solid rgba(99, 81, 190, 1);
      background-color: rgba(99, 81, 190, 0.2);
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  background-color: #f7fafc;
}

.content-wrap {
  padding-left: 260px;
  padding-top: 60px;
  // background-color: lightblue;
  .content {
    padding: 20px;
    min-width: 1280px; // TODO test
    background-color: #f7fafc;
  }
}
